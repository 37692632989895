import { noop } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import LoadMore from 'react/generic/pagination/loadMore/LoadMore';

import messages from './load-more.messages';
import classNames from './loadMore.module.scss';

export default WrappedComponent => class withLoadMore extends Component {
  static propTypes = {
    currentCount: PropTypes.number,
    totalCount: PropTypes.number,
    onClickLoadMore: PropTypes.func,
  };

  static defaultProps = {
    currentCount: 0,
    totalCount: 0,
    onClickLoadMore: noop,
  };

  renderCount = (currentCount, totalCount) => (
    <FormattedMessage {...messages.COUNT} values={{ currentCount, totalCount }} />
  );

  /** @returns {object} JSX. */
  render() {
    const {
      currentCount,
      totalCount,
      onClickLoadMore,
      ...props
    } = this.props;

    return (
      <>
        <WrappedComponent
          {...props}
        />

        <div className={classNames.loadMoreContainer}>
          <LoadMore
            currentCount={currentCount}
            totalCount={totalCount}
            onClickLoadMore={onClickLoadMore}
            renderCount={this.renderCount}
          />
        </div>
      </>
    );
  }
};
