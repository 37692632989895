import { compose, lifecycle } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { selectUserPreferences, selectUserPreferencesLoading } from 'redux/users/selectors';
import { getCurrentUserPreferences } from 'redux/users/actions';

export default compose(
  connect(
    state => ({
      preferences: selectUserPreferences(state),
      loading: selectUserPreferencesLoading(state),
    }),
    {
      getPreferences: getCurrentUserPreferences,
    },
  ),

  lifecycle({
    /** Load user preferences on mount. */
    componentDidMount() {
      this.props.getPreferences();
    },
  }),
);
