import React, {
  memo,
} from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { defaultFilters } from 'react/business/facets/connect/withFiltersControllerInState';

import KindPresentation from './filters/kinds/Kinds.presentation';
import connectKinds from './filters/kinds/kinds.connect';
import connectTitle from './filters/title/title.connect';
import ProductSortPresentation from './filters/sort/products/ProductSort.presentation';
import connectProductSort from './filters/sort/products/productSort.connect';

import { getMyCreatedProducts, getProductsToValidate } from '../../../redux/users/actions';
import TitlePresentation from './filters/title/Title.presentation';

const enhancer = compose(
  connect(
    null,
    (dispatch, props) => ({
      onGetProducts: async (filters) => {
        if (props.context === 'myProducts') {
          await dispatch(getMyCreatedProducts(filters));
        } else {
          await dispatch(getProductsToValidate(filters));
        }
      },
    }),
  ),

  memo,
);

const Kinds = connectKinds(KindPresentation);
const Title = connectTitle(TitlePresentation);
const ProductSort = connectProductSort(ProductSortPresentation);

const FacetsContainerCreatedAndValidatePage = ({
   filters,
   onSetFilters,
   onResetFilters,
   children,
   onGetProducts,
 }) => {
  const onSetFiltersAndFetchProducts = async (newFilters) => {
    const updatedFilters = {
      ...filters,
      ...newFilters,
    };
    onSetFilters(updatedFilters);
    await onGetProducts(updatedFilters, true);
  };

  const onResetFiltersAndFetchProducts = async () => {
    onResetFilters();
    await onGetProducts(defaultFilters, true);
  };

  return children({
    commonFilters: [
      <Kinds key="kinds" filters={filters} onSetFilters={onSetFiltersAndFetchProducts} />,
      <Title key="title" filters={filters} onSetFilters={onSetFiltersAndFetchProducts} />,
    ],

    sortFilter: <ProductSort filters={filters} onSetFilters={onSetFiltersAndFetchProducts} />,

    onResetFilters: onResetFiltersAndFetchProducts,
  });
};

FacetsContainerCreatedAndValidatePage.propTypes = {
  filters: PropTypes.shape().isRequired,
  onSetFilters: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  shouldShowMoreFilters: PropTypes.bool,
  onHideMoreFilters: PropTypes.func,
  onShowMoreFilters: PropTypes.func,
  children: PropTypes.func.isRequired,
};

FacetsContainerCreatedAndValidatePage.displayName = 'FacetsContainerMyCreatedAndValidatePage';

export default enhancer(FacetsContainerCreatedAndValidatePage);
