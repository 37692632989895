import PropTypes from 'prop-types';

import { dateShape } from './generic';

export const notificationShape = PropTypes.shape({
  /** Technical unique id. */
  id: PropTypes.string,
  /** Text of the notification. Will probably be removed. */
  content: PropTypes.string,
  /** Link. Will probably be removed. */
  link: PropTypes.string,
  /** Is the notification read. */
  read: PropTypes.bool,
  /** Issue date. */
  date: dateShape,
  /** True if the corresponding product is archived. */
  disableLink: PropTypes.bool,

});
