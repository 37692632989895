import React from 'react';
import PropTypes from 'prop-types';

/**
 * By default, map params directly to the props.
 *
 * @param {object} params - URL parameters.
 * @returns {object} Mapped props.
 */
const defaultMapParamsToProps = params => ({ ...params });

/**
 * @param {Function} mapParamsToProps - Takes route parameters object, returns mapped props.
 * @returns {Function} HOC.
 */
const WithRouteParams = (mapParamsToProps = defaultMapParamsToProps) => (WrappedComponent) => {
  const EnhancedComponent = ({ match: { params }, ...props }) => (
    <WrappedComponent {...props} {...mapParamsToProps(params)} />
  );

  EnhancedComponent.propTypes = {
    match: PropTypes.shape({
      /** Route params. */
      params: PropTypes.shape({}),
    }).isRequired,
  };

  return EnhancedComponent;
};

export default WithRouteParams;
