import { noop } from 'lodash';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

import { notificationShape } from 'shapes/notification';
import NotificationFilters from './NotificationFilters';

import messages from './notification-list.messages';
import classNames from './notificationList.module.scss';

const enhancer = compose(
  memo,
);

const NotificationList = ({
  notifications,
  renderNotification,
  className,
  ...props
}) => (
  <>
    <NotificationFilters {...props} />
    {notifications.length > 0 ? (
      <ul className={cn(className, classNames.notificationList)}>
        { notifications.map(notification => renderNotification({ notification })) }
      </ul>
    ) : (
      <div className={classNames.empty}>
        <FormattedMessage {...messages.NO_DATA} />
      </div>
    )}
  </>
);

NotificationList.displayName = 'NotificationList';

NotificationList.propTypes = {
  notifications: PropTypes.arrayOf(notificationShape),
  renderNotification: PropTypes.func,
  className: PropTypes.string,
};

NotificationList.defaultProps = {
  notifications: [],
  renderNotification: noop,
  className: null,
};

export default enhancer(NotificationList);
