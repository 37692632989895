import connect from 'react/hoc/connectProxy';

import { removeNotification } from 'redux/notifications/actions';

/**
 * Expose removeNotification action.
 */
export default connect(
  null,
  {
    onRemoveNotification: removeNotification,
  },
);
