import React from 'react';

import { FormattedMessage } from 'react-intl';
import messages from 'react/business/facets/facets.messages';
import { FILTERS } from 'react/business/facets/facets.constants';
import SelectFormElement from 'react/business/facets/formElements/SelectFormElement';

export default props => (
  <SelectFormElement
    {...props}
    label={<FormattedMessage {...messages.FACET_KINDS} />}
    filterKey={FILTERS.KINDS_ID}
  />
);
