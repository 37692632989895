import React, { memo } from 'react';
import {
  compose,
  mapProps,
  withPropsOnChange,
  lifecycle,
} from 'recompose';

import connect from 'react/hoc/connectProxy';

import { NOTIFICATION_PAGINATION_ITEM_NUMBER } from 'config/constants';

import ConnectedNotificationList from 'react/business/notifications/notificationList/connectedNotificationList';
import withGetNotificationsConnect from 'react/business/notifications/connect/withGetNotifications.connect';
import withNotificationsCountConnect from 'react/business/notifications/connect/withNotificationsCount.connect';
import withPaginatedNotificationsContainer from 'react/business/notifications/pagination/PaginatedNotifications.container';
import withLoadMore from 'react/generic/pagination/loadMore/withLoadMore';
import withNotificationsLoadMorePresentation from 'react/business/notifications/pagination/loadMore/LoadMore.presentation';
import { selectNotificationsList, selectNotificationsFilter } from 'redux/notifications/selectors';
import {
  setNotificationFilter,
  setNotificationPagination,
  setNotificationsAsRead,
  getUnreadNotificationsCount,
} from 'redux/notifications/actions';
import { ACTIONS } from 'redux/notifications/constants';

import { getNotificationIds, getUnreadNotifications } from 'react/business/notifications/utils';

import classNames from './preferences.notifications.module.scss';

const enhancer = compose(
  memo,
);

const PaginatedNotificationList = compose(
  withGetNotificationsConnect,

  connect(
    state => ({
      notifications: selectNotificationsList(state),
      filter: selectNotificationsFilter(state),
    }),
    {
      setNotificationFilter,
      setNotificationPagination,
      setNotificationsAsRead,
      getUnreadNotificationsCount,
    },
  ),

  lifecycle({
    /** Load notification on mount. */
    async componentDidMount() {
      const action = await this.props.onGetNotifications({
        limit: NOTIFICATION_PAGINATION_ITEM_NUMBER,
        types: this.props.filter,
      });

      if (action.type === ACTIONS.GET_NOTIFICATIONS_SUCCESS) {
        const unreadNotifications = getUnreadNotifications(this.props.notifications);

        if (unreadNotifications.length) {
          await this.props.setNotificationsAsRead(getNotificationIds(unreadNotifications));
          this.props.getUnreadNotificationsCount();
        }
      }
    },
  }),

  withPropsOnChange(
    ['filter'],
    props => ({
      // Change filter and reset pagination limit.
      onClickFilterButton: (filter) => {
        props.setNotificationPagination(NOTIFICATION_PAGINATION_ITEM_NUMBER);
        props.setNotificationFilter(filter);
        props.onGetNotifications({
          limit: NOTIFICATION_PAGINATION_ITEM_NUMBER,
          types: filter,
        });
      },
    }),
  ),

  // Get the total count of notifications.
  withNotificationsCountConnect,

  // Map notificationsCount to totalCount for load more component.
  mapProps(({ notificationsCount, ...props }) => ({
    ...props,
    totalCount: notificationsCount,
  })),

  // Use pagination HOC.
  withPaginatedNotificationsContainer,
  withLoadMore({
    itemsPropName: 'notifications',
    totalCountPropName: 'notificationsCount',
    pageSize: NOTIFICATION_PAGINATION_ITEM_NUMBER,
  }),
  withNotificationsLoadMorePresentation,
)(ConnectedNotificationList);

const PreferencesNotificationsPage = () => (
  <div className={classNames.content}>
    <PaginatedNotificationList />
  </div>
);

PreferencesNotificationsPage.displayName = 'PreferencesNotificationsPage';

export default enhancer(PreferencesNotificationsPage);
