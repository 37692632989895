import React, { memo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { noop } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Popover } from 'antd';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import Button from 'react/generic/button/Button';
import { NOTIFICATION_FILTERS } from 'config/constants';

import MoreActionsMenu from './menu/MoreActionsMenu';
import classNames from './notificationFilters.module.scss';
import messages from './notification-list.messages';

const enhancer = compose(
  withHandlers({
    onClickFilterButtonAll: ({
      onClickFilterButton,
    }) => () => onClickFilterButton(NOTIFICATION_FILTERS.ALL),
    onClickFilterButtonReviews: ({
      onClickFilterButton,
    }) => () => onClickFilterButton(NOTIFICATION_FILTERS.REVIEWS),
    onClickFilterButtonUpdates: ({
      onClickFilterButton,
    }) => () => onClickFilterButton(NOTIFICATION_FILTERS.UPDATES),
    onClickFilterButtonSuggestions: ({
      onClickFilterButton,
    }) => () => onClickFilterButton(NOTIFICATION_FILTERS.SUGGESTIONS),
  }),

  memo,
);

const NotificationsFilters = ({
  filter,
  onClickFilterButtonAll,
  onClickFilterButtonReviews,
  onClickFilterButtonUpdates,
  onClickFilterButtonSuggestions,
  onClickSetAllRead,
  onClickRemoveAll,
}) => {
  const [isMenuDisplayed, setIsMenuDisplayed] = useState(false);

  const handleClick = useCallback(
    () => setIsMenuDisplayed(!isMenuDisplayed),
    [isMenuDisplayed],
  );

  return (
    <div className={classNames.container}>
      <div className={classNames.filters}>
        <Button
          className={classNames.filterButton}
          outline={filter !== NOTIFICATION_FILTERS.ALL}
          onClick={onClickFilterButtonAll}
        >
          <FormattedMessage {...messages.BUTTON_TEXT_ALL} />
        </Button>
        <Button
          className={classNames.filterButton}
          outline={filter !== NOTIFICATION_FILTERS.REVIEWS}
          onClick={onClickFilterButtonReviews}
        >
          <FormattedMessage {...messages.BUTTON_TEXT_REVIEWS} />
        </Button>
        <Button
          className={classNames.filterButton}
          outline={filter !== NOTIFICATION_FILTERS.UPDATES}
          onClick={onClickFilterButtonUpdates}
        >
          <FormattedMessage {...messages.BUTTON_TEXT_UPDATE} />
        </Button>
        <Button
          className={classNames.filterButton}
          outline={filter !== NOTIFICATION_FILTERS.SUGGESTIONS}
          onClick={onClickFilterButtonSuggestions}
        >
          <FormattedMessage {...messages.BUTTON_TEXT_SUGGESTIONS} />
        </Button>
      </div>
      <div className={classNames.iconContainer}>
        <Popover
          content={(
            <MoreActionsMenu
              onClickAllRead={onClickSetAllRead}
              onClickRemoveAll={onClickRemoveAll}
              onClick={handleClick}
            />
          )}
          className={classNames.popover}
          overlayClassName={classNames.moreActionsMenu}
          trigger="click"
          visible={isMenuDisplayed}
          onVisibleChange={setIsMenuDisplayed}
          placement="topRight"
          arrowPointAtCenter
        >
          <div className={classNames.circle}>
            <Asset name={ICON_MAP.plus} />
          </div>
        </Popover>
      </div>
    </div>
  );
};

NotificationsFilters.displayName = 'NotificationsFilters';

NotificationsFilters.propTypes = {
  filter: PropTypes.arrayOf(PropTypes.string),
  onClickFilterButtonAll: PropTypes.func,
  onClickFilterButtonReviews: PropTypes.func,
  onClickFilterButtonUpdates: PropTypes.func,
  onClickFilterButtonSuggestions: PropTypes.func,
  onClickSetAllRead: PropTypes.func,
  onClickRemoveAll: PropTypes.func,
};

NotificationsFilters.defaultProps = {
  filter: NOTIFICATION_FILTERS.ALL,
  onClickFilterButtonAll: noop,
  onClickFilterButtonReviews: noop,
  onClickFilterButtonUpdates: noop,
  onClickFilterButtonSuggestions: noop,
  onClickSetAllRead: noop,
  onClickRemoveAll: noop,
};

export default enhancer(NotificationsFilters);
