import { compose, withProps } from 'recompose';
import { NOTIFICATION_PAGINATION_ITEM_NUMBER } from 'config/constants';
import connect from 'react/hoc/connectProxy';

import { ACTIONS } from 'redux/notifications/constants';
import { setNotificationPagination } from 'redux/notifications/actions';

import { getUnreadNotifications, getNotificationIds } from '../utils';

export default compose(
  connect(
    null,
    {
      setNotificationPagination,
    },
  ),

  withProps(props => ({
    onLoadPage: async (page, { currentCount = 0 } = {}) => {
      props.setNotificationPagination(currentCount + NOTIFICATION_PAGINATION_ITEM_NUMBER);
      const action = props.onGetNotifications({
        limit: currentCount + NOTIFICATION_PAGINATION_ITEM_NUMBER,
        types: props.filter,
      });

      if (action.type === ACTIONS.GET_NOTIFICATIONS_SUCCESS) {
        const unreadNotifications = getUnreadNotifications(this.props.notifications);

        if (unreadNotifications.length) {
          this.props.setNotificationsAsRead(getNotificationIds(unreadNotifications));
        }
      }
    },
  })),
);
