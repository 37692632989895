import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, lifecycle, withProps } from 'recompose';
import connect from 'react/hoc/connectProxy';
import { PRODUCT_SORTING } from 'config/constants';
import { productShape } from 'shapes/product';
import { userShape } from 'shapes/user';
import {
  selectTotalProductsToValidateCount,
  selectProductsToValidateList,
  selectUsersByExternalIds,
} from 'redux/users/selectors';
import { FormattedMessage } from 'react-intl';
import withLoadMore from 'react/generic/pagination/loadMore/withLoadMore';
import withExternalUserLazyLoading from 'react/business/user/lazyLoad/withExternalUserLazyLoading';
import ProductsLoadMore from 'react/business/products/pagination/loadMore/LoadMore.presentation';
import ProductsCreatedTable from 'react/business/preferences/productsCreatedTable/ProductsCreatedTable';

import { getProductsToValidate } from 'redux/users/actions';
import { withRouter } from 'react-router';
import messages from './preferences.validate.messages';
import classNames from './preferences.created.module.scss';
import { FILTERS_DISPLAY_TYPES } from '../../business/facets/facets.constants';
import FiltersWithContentLayout from '../../business/facets/layout/FiltersWithContentLayout';
import withFiltersControllerInState from '../../business/facets/connect/withFiltersControllerInState';
import FacetsContainerCreatedAndValidatePage from '../../business/facets/facetsContainerCreatedAndValidatePage';

const PAGINATION_ITEM_NUMBER = 10;

const enhancer = compose(
  connect(state => ({
    productsToValidate: selectProductsToValidateList(state),
    totalCount: selectTotalProductsToValidateCount(state),
  }), {
    getProductsToValidate,
  }),

  lifecycle({
    /** Load my created products on mount. */
    async componentDidMount() {
      const params = {
        sort: PRODUCT_SORTING.CREATED_DATE_DESC,
        limit: PAGINATION_ITEM_NUMBER,
      };

      this.props.getProductsToValidate(params);
    },
  }),

  // Lazy load and get authors from store.
  compose(
    connect(
      (state, props) => ({
        authors: selectUsersByExternalIds(
          state,
          props.productsToValidate?.map(p => p.author).filter(Boolean),
        ),
      }),
    ),

    withExternalUserLazyLoading({
      getIdsToLoadFromProps: props => props.productsToValidate?.map(p => p.author).filter(Boolean),
    }),
  ),

  withFiltersControllerInState,

  // Load more component.
  withProps(props => ({
    onLoadPage: (page, { currentCount = 0 } = {}) => {
      const params = {
        sort: props.filters?.sort ?? PRODUCT_SORTING.CREATED_DATE_DESC,
        limit: currentCount + PAGINATION_ITEM_NUMBER,
        kinds: props.filters?.kinds,
        title: props.filters?.title || undefined,
      };
      props.getProductsToValidate(params);
    },
  })),

  withLoadMore({ itemsPropName: 'productsToValidate', pageSize: PAGINATION_ITEM_NUMBER }),
  ProductsLoadMore,

  memo,
);

const ConnectedFacets = compose(
  memo,
  withRouter,
  withProps({ context: 'validateProducts' }),
)(FacetsContainerCreatedAndValidatePage);

const PreferencesValidatePage = ({
  productsToValidate,
  authors,
  filters,
  onSetFilters,
  onResetFilters,
}) => (
  <div className={classNames.container}>
    <div className={classNames.title}>
      <FormattedMessage {...messages.VALIDATE_PRODUCT_AND_COMPONENT_TITLE} />
    </div>
    <div className={classNames.table}>
      <FiltersWithContentLayout
        filtersComponent={
          (props) => (
            <ConnectedFacets
              {...props}
              onSetFilters={onSetFilters}
              onResetFilters={onResetFilters}
              filters={filters}
            />
          )}
        content={(
          <ProductsCreatedTable
            products={productsToValidate}
            authors={authors}
            view="validate"
          />
            )}
        type={FILTERS_DISPLAY_TYPES.FILTERS_TOP}
      />
    </div>
  </div>
);

PreferencesValidatePage.displayName = 'PreferencesValidatePage';
PreferencesValidatePage.propTypes = {
  productsToValidate: PropTypes.arrayOf(productShape),
  totalCount: PropTypes.number,
  authors: PropTypes.objectOf(userShape),
};
PreferencesValidatePage.defaultProps = {
  productsToValidate: [],
  totalCount: 0,
  authors: {},
};

export default enhancer(PreferencesValidatePage);
