import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';

import { userShape } from 'shapes/user';
import { productShape } from 'shapes/product';

import { FormattedMessage } from 'react-intl';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import { Tooltip } from 'antd';
import ProductRow from './productRow/ProductRow';

import messages from './products-created-table.messages';
import classNames from './products-created-table.module.scss';
import { COMPLETION_RATE_LINK } from './products-created-table.constants';

const ProductsCreatedTable = ({
                                products,
                                authors,
                                onRequestDelete,
                                view,
                              }) => (products.length ?
    (
      <table className={classNames.table}>
        <thead>
          <tr>
            <th><FormattedMessage {...messages.TABLE_PRODUCT_NAME} /></th>
            <th className={classNames.centered}><FormattedMessage {...messages.TABLE_KIND} /></th>
            <th><FormattedMessage {...messages.TABLE_CREATED_DATE} /></th>
            <th><FormattedMessage {...messages.TABLE_CREATED_BY} /></th>
            <th><FormattedMessage {...messages.TABLE_STATUS} /></th>
            <th>
              <FormattedMessage {...messages.TABLE_COMPLETION_RATE} />
              <Tooltip
                placement="top"
                title={(
                  <FormattedMessage
                    {...messages.TABLE_COMPLETION_RATE_TOOLTIP}
                    values={{
                    link: (
                      <a href={COMPLETION_RATE_LINK} target="_blank" rel="noopener noreferrer" key="id_all">
                        <FormattedMessage {...messages.TABLE_COMPLETION_RATE_LINK} />
                      </a>
                    ),
                  }}
                  />
              )}
              >
                <div className={classNames.tooltipCompletionRate}>
                  <Asset name={ICON_MAP.info} />
                </div>
              </Tooltip>
            </th>
            <th><FormattedMessage {...messages.TABLE_UPDATED_DATE} /></th>
            <th className={classNames.centered}><FormattedMessage {...messages.TABLE_ACTIONS} /></th>
          </tr>
        </thead>
        <tbody>
          {products.map(product => (
            <ProductRow
              key={product.id}
              product={product}
              author={authors[product.author]}
              onRequestDelete={onRequestDelete}
              view={view}
            />
        ))}
        </tbody>
      </table>
    )
    : (
      <div className={classNames.empty}>
        <FormattedMessage {...messages.NO_DATA} />
      </div>
));

ProductsCreatedTable.displayName = 'ProductsCreatedTable';

ProductsCreatedTable.propTypes = {
  products: PropTypes.arrayOf(productShape),
  authors: PropTypes.objectOf(userShape),
  onRequestDelete: PropTypes.func,
  view: PropTypes.string,
};

ProductsCreatedTable.defaultProps = {
  products: [],
  authors: {},
  onRequestDelete: noop,
  view: '',
};

export default ProductsCreatedTable;
