import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';

import withRouteParams from 'react/hoc/withRouteParams';
import PreferencesPage from 'react/pages/preferences/PreferencesPageContainer';
import ApplicationLayout from 'react/business/layout/ApplicationLayout';

import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import messages from './preferences.messages';

const mapRouteParamsToProps = ({ view }) => ({
  view,
});

const enhancer = compose(
  withRouteParams(mapRouteParamsToProps),
);

const Preferences = ({ view }) => (
  <ApplicationLayout>
    <FormattedMessageChildren {...messages.TITLE}>
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>

    <PreferencesPage view={view} />
  </ApplicationLayout>
);

Preferences.displayName = 'Preferences';

Preferences.propTypes = {
  view: PropTypes.string,
};

Preferences.defaultProps = {
  view: '',
};

export default enhancer(Preferences);
