import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { Menu } from 'antd';
import { FormattedMessage } from 'react-intl';

import messages from './more-actions-menu.messages';
import classNames from './more-actions-menu.module.scss';

const { Item } = Menu;

const MoreActionsMenu = ({
  onClickAllRead,
  onClickRemoveAll,
  onClick,
}) => (
  <Menu
    mode="vertical"
    onClick={onClick}
    className={classNames.moreActionsMenu}
  >
    <Item key="all_read" onClick={onClickAllRead} className={classNames.item}>
      <FormattedMessage {...messages.ALL_READ} />
    </Item>
    <Item key="all_delete" onClick={onClickRemoveAll} className={classNames.item}>
      <FormattedMessage {...messages.ALL_DELETE} />
    </Item>
  </Menu>
);

MoreActionsMenu.propTypes = {
  onClickAllRead: PropTypes.func,
  onClickRemoveAll: PropTypes.func,
  onClick: PropTypes.func,
};

MoreActionsMenu.defaultProps = {
  onClickAllRead: noop,
  onClickRemoveAll: noop,
  onClick: noop,
};

MoreActionsMenu.displayName = 'MoreActionsMenu';

export default MoreActionsMenu;
