import { defineMessages } from 'react-intl';

export default defineMessages({
  ALL_READ: {
    id: 'notifications.more.allRead',
    defaultMessage: 'Mark all notifications as read',
  },
  ALL_DELETE: {
    id: 'notifications.more.allDelete',
    defaultMessage: 'Delete all notifications',
  },
});
