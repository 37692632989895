import React from 'react';
import { compose } from 'recompose';

import NotificationListPresentation from './NotificationList.presentation';
import NotificationListItemPresentation from './NotificationListItem.presentation';
import withNotificationsManagementFromPropsConnect from '../connect/withNotificationsManagementFromProps.connect';
import withIsRemoveNotificationLoadingConnect from '../connect/withIsRemoveNotificationLoading.connect';
import withIsSetNotificationReadLoadingConnect from '../connect/withIsSetNotificationReadLoading.connect';
import withMoreActionsConnect from '../connect/withMoreActions.connect';

const NotificationListItem = compose(
  withNotificationsManagementFromPropsConnect(),
  withIsRemoveNotificationLoadingConnect(),
  withIsSetNotificationReadLoadingConnect(),
)(NotificationListItemPresentation);

// This is not a React component.
// eslint-disable-next-line react/prop-types
const renderNotification = ({ notification }) => (
  <NotificationListItem
    key={notification.id}
    notification={notification}
  />
);

export default compose(
  withMoreActionsConnect,
)(props => (
  <NotificationListPresentation
    {...props}
    renderNotification={renderNotification}
  />
));
