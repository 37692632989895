import { defineMessages } from 'react-intl';

export default defineMessages({
  BUTTON_TEXT_ALL: {
    id: 'notifications.button.label.all',
    defaultMessage: 'All',
  },
  BUTTON_TEXT_REVIEWS: {
    id: 'notifications.button.label.reviews',
    defaultMessage: 'Feedbacks',
  },
  BUTTON_TEXT_UPDATE: {
    id: 'notifications.button.label.update',
    defaultMessage: 'Product & component updates',
  },
  BUTTON_TEXT_SUGGESTIONS: {
    id: 'notifications.button.label.suggestions',
    defaultMessage: 'Suggestions',
  },
  NO_DATA: {
    id: 'notifications.nodata',
    defaultMessage: 'You do not have any notification.',
  },
});
