import React from 'react';

import messages from 'react/business/facets/facets.messages';
import { FILTERS } from 'react/business/facets/facets.constants';
import InputFormElement from 'react/business/facets/formElements/InputFormElement';

export default props => (
  <InputFormElement
    {...props}
    label={messages.FACET_TITLE}
    filterKey={FILTERS.TITLE_ID}
  />
);
