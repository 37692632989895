import { compose, mapProps } from 'recompose';

import withSetNotificationReadConnect from './withSetNotificationRead.connect';
import { defaultGetNotificationIdFromProps } from './defaultGetNotificationIdFromProps';

/**
 * Like withSetNotificationRead hoc, but reads the notificationId from the component props.
 *
 * @param {Function} getNotificationIdFromProps - Get notification id from props. (props) => string.
 * Default is props.notification.id.
 * @returns {Function} HOC.
 */
export default (getNotificationIdFromProps = defaultGetNotificationIdFromProps) => compose(
  withSetNotificationReadConnect,
  mapProps(props => ({
    ...props,
    onSetNotificationRead: isRead => props.onSetNotificationRead(
      getNotificationIdFromProps(props),
      isRead,
    ),
  })),
);
