import React from 'react';
import { keyBy } from 'lodash';
import { FormattedMessage } from 'react-intl';
import productMessages from 'react/business/products/product.messages';

export const kindOptions = [
  { value: 'product', label: <FormattedMessage {...productMessages.KIND_PRODUCT} /> },
  { value: 'component', label: <FormattedMessage {...productMessages.KIND_COMPONENT} /> },
];

export const kindsOptionsMap = keyBy(kindOptions, 'value');
