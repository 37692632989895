import { compose, withProps } from 'recompose';
import { kindOptions } from 'react/business/products/kind/kind.constants';

const mappedKinds = kindOptions.map(stage => ({
  id: stage.value,
  name: stage.label,
}));

export default compose(
  withProps({
    values: mappedKinds,
  }),
);
