import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import {
  compose,
  lifecycle,
  withProps,
  withHandlers,
} from 'recompose';
import connect from 'react/hoc/connectProxy';
import { formatRoute } from 'react-router-named-routes';

import ROUTES from 'react/routes';
import { PRODUCT_SORTING } from 'config/constants';
import { productShape } from 'shapes/product';
import { userShape } from 'shapes/user';
import {
  selectCurrentUserCreatedProductList,
  selectUsersByExternalIds,
  selectCurrentUserTotalCreatedProductCount,
} from 'redux/users/selectors';
import { getMyCreatedProducts } from 'redux/users/actions';
import { deleteProduct } from 'redux/products/actions';
import { getCommunities } from 'redux/communities/actions';
import { getBusinessUnits } from 'redux/businessUnits/actions';
import { ACTIONS as PRODUCT_ACTIONS } from 'redux/products/constants';

import { Icon } from 'antd';
import { FormattedMessage } from 'react-intl';
import Link from 'react/generic/link/Link';
import withLoadMore from 'react/generic/pagination/loadMore/withLoadMore';
import withExternalUserLazyLoading from 'react/business/user/lazyLoad/withExternalUserLazyLoading';
import ProductsLoadMore from 'react/business/products/pagination/loadMore/LoadMore.presentation';
import ProductsCreatedTable from 'react/business/preferences/productsCreatedTable/ProductsCreatedTable';

import messages from './preferences.created.messages';
import classNames from './preferences.created.module.scss';
import { FILTERS_DISPLAY_TYPES } from '../../business/facets/facets.constants';
import FiltersWithContentLayout from '../../business/facets/layout/FiltersWithContentLayout';
import FacetsContainerCreatedAndValidatePage from '../../business/facets/facetsContainerCreatedAndValidatePage';
import withFiltersControllerInState from '../../business/facets/connect/withFiltersControllerInState';

const PAGINATION_ITEM_NUMBER = 10;

const enhancer = compose(
  connect(state => ({
    myProducts: selectCurrentUserCreatedProductList(state),
    totalCount: selectCurrentUserTotalCreatedProductCount(state),
  }), {
    getMyCreatedProducts,
    onRequestDelete: deleteProduct,
  }),

  withHandlers({
    // Encapsulate the redux action to return the success state of the action.
    onRequestDelete: ({ onRequestDelete }) => async (...args) => {
      const action = await onRequestDelete(...args);
      return { success: action.type === PRODUCT_ACTIONS.DELETE_PRODUCT_SUCCESS };
    },
  }),

  lifecycle({
    /** Load my created products on mount. */
    componentDidMount() {
      const params = {
        sort: PRODUCT_SORTING.CREATED_DATE_DESC,
        limit: PAGINATION_ITEM_NUMBER,
      };
      this.props.getMyCreatedProducts(params);
    },
  }),

  // Lazy load and get authors from store.
  compose(
    connect(
      (state, props) => ({
        authors: selectUsersByExternalIds(
          state,
          props.myProducts?.map(p => p.author).filter(Boolean),
        ),
      }),
      { getCommunities, getBusinessUnits },
    ),

    withExternalUserLazyLoading({
      getIdsToLoadFromProps: props => props.myProducts?.map(p => p.author).filter(Boolean),
    }),
  ),

  withFiltersControllerInState,

  // Load more component.
  withProps(props => ({
    onLoadPage: (page, { currentCount = 0 } = {}) => {
      const params = {
        sort: props.filters?.sort ?? PRODUCT_SORTING.CREATED_DATE_DESC,
        limit: currentCount + PAGINATION_ITEM_NUMBER,
        kinds: props.filters?.kinds,
        title: props.filters?.title || undefined,
      };
      props.getMyCreatedProducts(params);
    },
  })),

  withLoadMore({ itemsPropName: 'myProducts', pageSize: PAGINATION_ITEM_NUMBER }),
  ProductsLoadMore,

  lifecycle({
    /**
     * Load communities list on mount.
     */
    async componentDidMount() {
      await Promise.all([this.props.getCommunities({
        populateDomains: true,
      }), this.props.getBusinessUnits()]);
    },
  }),
  memo,
);

const ConnectedFacets = compose(
  memo,
  withProps({ context: 'myProducts' }),
)(FacetsContainerCreatedAndValidatePage);

const PreferencesCreatedPage = ({
  myProducts,
  authors,
  onRequestDelete,
  filters,
  onSetFilters,
  onResetFilters,
}) => (
  <div className={classNames.container}>
    <div className={classNames.title}>
      <FormattedMessage {...messages.CREATED_PRODUCT_AND_COMPONENT_TITLE} />
    </div>
    <div className={classNames.table}>
      <FiltersWithContentLayout
        filtersComponent={
            (props) => (
              <ConnectedFacets
                {...props}
                onSetFilters={onSetFilters}
                onResetFilters={onResetFilters}
                filters={filters}
              />
            )}
        content={(
          <ProductsCreatedTable
            products={myProducts}
            authors={authors}
            onRequestDelete={onRequestDelete}
          />
          )}
        type={FILTERS_DISPLAY_TYPES.FILTERS_TOP}
      />
    </div>
    <div className={classNames.addProduct}>
      <Link to={formatRoute(ROUTES.PRODUCT.CREATE_FORM.FORM, { step: 'request' })}>
        <Icon type="plus-circle" />
        <FormattedMessage {...messages.CREATE_NEW_PRODUCT} />
      </Link>
    </div>
  </div>
  );

PreferencesCreatedPage.displayName = 'PreferencesCreatedPage';
PreferencesCreatedPage.propTypes = {
  myProducts: PropTypes.arrayOf(productShape),
  totalCount: PropTypes.number,
  authors: PropTypes.objectOf(userShape),
  onRequestDelete: PropTypes.func,
};
PreferencesCreatedPage.defaultProps = {
  myProducts: [],
  totalCount: 0,
  authors: {},
  onRequestDelete: noop,
};

export default enhancer(PreferencesCreatedPage);
