import PropTypes from 'prop-types';
import { AVAILABLE_LANGUAGES } from 'config/languages';

export const preferencesShape = PropTypes.shape({
  language: PropTypes.oneOf(AVAILABLE_LANGUAGES),
  alwaysTranslateContent: PropTypes.bool,
  replyNotificationsAvailable: PropTypes.bool,
  notificationMailingFrequency: PropTypes.string,
  replyEmailNotificationsAvailable: PropTypes.bool,
  reviewsOnPage: PropTypes.number,
  suggestionsOnPage: PropTypes.number,
});
