import { compose, mapProps } from 'recompose';

import withRemoveNotificationConnect from './withRemoveNotification.connect';
import { defaultGetNotificationIdFromProps } from './defaultGetNotificationIdFromProps';

/**
 * Like withRemoveNotification hoc, but reads the notificationId from the component props.
 *
 * @param {Function} getNotificationIdFromProps - Get notification id from props. (props) => string.
 * Default is props.notification.id.
 * @returns {Function} HOC.
 */
export default (getNotificationIdFromProps = defaultGetNotificationIdFromProps) => compose(
  withRemoveNotificationConnect,
  mapProps(props => ({
    ...props,
    onRemoveNotification: () => props.onRemoveNotification(getNotificationIdFromProps(props)),
  })),
);
