import * as UsersService from 'services/users';
import { ACTIONS } from './constants';

export const GET_CURRENT_USER_PRODUCTS_HISTORY = () => ({
  type: ACTIONS.GET_CURRENT_USER_PRODUCTS_HISTORY,
});

export const GET_CURRENT_USER_PRODUCTS_HISTORY_SUCCESS = history => ({
  type: ACTIONS.GET_CURRENT_USER_PRODUCTS_HISTORY_SUCCESS,
  history,
});

export const GET_CURRENT_USER_PRODUCTS_HISTORY_FAILURE = error => ({
  type: ACTIONS.GET_CURRENT_USER_PRODUCTS_HISTORY_FAILURE,
  error,
});

export const getProductsHistory = (query = {}) => async (dispatch) => {
  dispatch(GET_CURRENT_USER_PRODUCTS_HISTORY());

  try {
    const history = await UsersService.getProductsHistory(query);
    return dispatch(GET_CURRENT_USER_PRODUCTS_HISTORY_SUCCESS(history));
  } catch (err) {
    return dispatch(GET_CURRENT_USER_PRODUCTS_HISTORY_FAILURE(err));
  }
};

export const POST_CURRENT_USER_PRODUCTS_HISTORY = idProduct => ({
  type: ACTIONS.POST_CURRENT_USER_PRODUCTS_HISTORY,
  idProduct,
});

export const POST_CURRENT_USER_PRODUCTS_HISTORY_SUCCESS = history => ({
  type: ACTIONS.POST_CURRENT_USER_PRODUCTS_HISTORY_SUCCESS,
  history,
});

export const POST_CURRENT_USER_PRODUCTS_HISTORY_FAILURE = error => ({
  type: ACTIONS.POST_CURRENT_USER_PRODUCTS_HISTORY_FAILURE,
  error,
});

export const postProductHistory = productId => async (dispatch) => {
  dispatch(POST_CURRENT_USER_PRODUCTS_HISTORY(productId));

  try {
    const history = await UsersService.postProductHistory(productId);
    return dispatch(POST_CURRENT_USER_PRODUCTS_HISTORY_SUCCESS(history));
  } catch (err) {
    return dispatch(POST_CURRENT_USER_PRODUCTS_HISTORY_FAILURE(err));
  }
};
