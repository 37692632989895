import connect from 'react/hoc/connectProxy';

import { setNotificationRead } from 'redux/notifications/actions';

/**
 * Expose setNotificationRead action.
 */
export default connect(
  null,
  {
    onSetNotificationRead: setNotificationRead,
  },
);
