import { noop } from 'lodash';
import React from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Input } from 'antd';

import { injectIntl } from 'react-intl';

import classNames from './selectFormElement.module.scss';

class InputFormElement extends React.PureComponent {
  static propTypes = {
    /* filters: PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
    ).isRequired, */
    filterKey: PropTypes.string.isRequired,
    onSetFilters: PropTypes.func,
    label: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onSetFilters: noop,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.filters[props.filterKey] || '',
    };
    this.debounceTimeout = null;
  }

  onChange = (e) => {
    this.setState({ value: e.target.value });
    clearTimeout(this.debounceTimeout);
    this.debounceTimeout
      = setTimeout(
        async () => {
          await this.props.onSetFilters({ [this.props.filterKey]: this.state.value });
        },
      1000);
  };

  componentDidUpdate(prevProps, prevState) {
    // If filters changed but internal state didn't, it means filters were reset.
    if (this.state.value === prevState.value
      && this.props.filters[this.props.filterKey] !== prevProps.filters[this.props.filterKey]
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ value: this.props.filters[this.props.filterKey] });
    }
  }

  /** @returns {object} JSX. */
  render() {
    const {
      label,
      intl,
    } = this.props;
    const { value } = this.state;

    return (
      <Input
        className={classNames.dropdown}
        onChange={this.onChange}
        placeholder={intl.formatMessage(label)}
        size="large"
        showSearch={false}
        value={value}
      />
    );
  }
}

export default compose(
  injectIntl,
)(InputFormElement);
