import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { PREFERENCES_VIEW } from 'config/constants';

import MySpaceHeader from 'react/business/mySpace/MySpaceHeader';
import PreferencesFollowedPage from './PreferencesFollowedPage';
import PreferencesCreatedPage from './PreferencesCreatedPage';
import PreferencesNotificationsPage from './PreferencesNotificationsPage';
import PreferencesHistoryPage from './PreferencesHistoryPage';
import PreferencesValidatePage from './PreferencesValidatePage';

const enhancer = compose(
  memo,
);

const displayCurrentView = (view) => {
  switch (view) {
    case PREFERENCES_VIEW.CREATED:
      return <PreferencesCreatedPage />;
    case PREFERENCES_VIEW.VALIDATE:
      return <PreferencesValidatePage />;
    case PREFERENCES_VIEW.FOLLOWED:
      return <PreferencesFollowedPage />;
    case PREFERENCES_VIEW.HISTORY:
      return <PreferencesHistoryPage />;
    case PREFERENCES_VIEW.NOTIFICATIONS:
    default:
      return <PreferencesNotificationsPage />;
  }
};

const PreferencesPage = ({ view }) => (
  <>
    <MySpaceHeader />
    { displayCurrentView(view) }
  </>
);

PreferencesPage.displayName = 'PreferencesPage';
PreferencesPage.propTypes = {
  view: PropTypes.string,
};
PreferencesPage.defaultProps = {
  view: '',
};

export default enhancer(PreferencesPage);
