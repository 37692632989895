import { compose } from 'recompose';
import connect from 'react/hoc/connectProxy';
import { setCurrentUserPreferences } from 'redux/users/actions';

export default compose(
  connect(
    null,
    {
      setPreferences: setCurrentUserPreferences,
    },
  ),
);
