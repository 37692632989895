import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import {
  compose,
  withStateHandlers,
  withHandlers,
  withProps,
} from 'recompose';
import moment from 'moment';
import { formatRoute } from 'react-router-named-routes';
import { withRouter } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withToastManager } from 'react-toast-notifications';

import ROUTES from 'react/routes';
import { COMPONENT_FORM_STEPS } from 'react/business/components/form/steps/component-form-steps.constants';
import { PRODUCT_FORM_STEPS } from 'react/business/products/form/steps/form-steps.constants';
import { userShape } from 'shapes/user';
import { productShape } from 'shapes/product';
import globalMessages from 'config/global.messages';
import { PRODUCT_VIEW } from 'config/constants';

import Dialog, {
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogAction,
} from 'react/generic/dialog/Dialog';
import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import Link from 'react/generic/link/Link';
import IconButton from 'react/generic/button/IconButton';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';
import UserName from 'react/generic/users/name/UserName';

import connect from 'react/hoc/connectProxy';
import { selectCanDeleteProduct, selectCanEditProduct, selectIsDeleteProductLoading } from 'redux/products/selectors';
import { getCompletionRateColor } from 'utils';

import ProductStatus from './status/ProductStatus';

import messages from './product-row.messages';
import classNames from './product-row.module.scss';
import Kind from '../../../../generic/kind/Kind';

const commonToastOptions = {
  autoDismiss: true,
  pauseOnHover: true,
  appearance: 'success',
};

const enhancer = compose(
  withRouter,
  withStateHandlers(
    () => ({
      isDeleteModalOpen: false,
    }),
    {
      onOpenDeleteModal: () => () => ({
        isDeleteModalOpen: true,
      }),

      onCloseDeleteModal: () => () => ({
        isDeleteModalOpen: false,
      }),
    },
  ),

  connect(
    (state, props) => ({
      isLoading: selectIsDeleteProductLoading(state, props.product.id),
      canEditProduct: selectCanEditProduct(state, props.product.id),
      canDeleteProduct: selectCanDeleteProduct(state, props.product.id),
    }),
    null,
  ),

  withToastManager,

  withHandlers({
    onRequestDelete: ({ product, onCloseDeleteModal, onRequestDelete }) => async () => {
      const { success } = await onRequestDelete?.(product.id) || {};

      if (success) {
        onCloseDeleteModal();
      }
    },

    addSuccessToast: ({ toastManager, product }) => () => {
      toastManager.add(
        <FormattedMessage
          {...messages.COPY_TO_CLIPBOARD_SUCCESS}
          values={{ productName: product.name }}
        />,
        {
          ...commonToastOptions,
        },
      );
    },

    onOpenSnippetConfiguration: ({ history, product }) => () => {
      history.push(formatRoute(ROUTES.SNIPPET_CONFIGURATION, {
        productId: product.id,
      }));
    },
  }),

  withProps(props => ({
    reviewReportUrl: `${window.origin}${formatRoute(ROUTES.PRODUCT.FEEDBACK_STANDALONE, {
      productId: props.product.id,
    })}?showFeatureSuggestionsLink=true`,
  })),
);

const ProductRow = ({
  product,
  author,
  isDeleteModalOpen,
  onOpenDeleteModal,
  onCloseDeleteModal,
  onRequestDelete,
  reviewReportUrl,
  onOpenSnippetConfiguration,
  addSuccessToast,
  isLoading,
  view,
  canEditProduct,
  canDeleteProduct,
}) => {
  const canEditOrIsValidatePage = view === 'validate' || canEditProduct;
  const productTitleLink = product?.parentDigitalProduct ?
    formatRoute(ROUTES.COMPONENT.PRESENTATION, {
      componentId: product.id,
      view: PRODUCT_VIEW.OVERVIEW,
    }) : formatRoute(ROUTES.PRODUCT.PRESENTATION, {
      productId: product.id,
      view: PRODUCT_VIEW.OVERVIEW,
    });
  return (
    <>
      <tr className={classNames.row}>
        <td className={classNames.cellName}>
          <Link
            to={productTitleLink}
          >
            {product.name}
          </Link>
        </td>

        <td><Kind isComponent={!!product?.parentDigitalProduct} /></td>

        <td>{product.created && moment(product.created).format('L')}</td>

        <td><UserName user={author} /></td>

        <td>
          <ProductStatus
            isDraft={product.isDraft}
            validationStatus={product.validation?.status}
            validators={product.validators}
          />
        </td>

        <td>
          <div className={`${classNames.completionRateColumn} ${classNames[getCompletionRateColor(product.completionRate)]}`}>
            {`${product.completionRate} %`}
          </div>
        </td>

        <td>{product.updated && moment(product.updated).format('L')}</td>

        <td className={classNames.cellUpdate}>
          <div className={classNames.actionsContainer}>
            <Tooltip title={
              canEditOrIsValidatePage ?
                <FormattedMessage {...messages.UPDATE_PRODUCT} />
                : <FormattedMessage {...messages.EDIT_NOT_ALLOWED} />
            }
            >
              <Link
                isDisabled={!canEditOrIsValidatePage}
                to={
                  product?.parentDigitalProduct ?
                    `${formatRoute(
                      ROUTES.COMPONENT.EDIT_FORM,
                      {
                        componentId: product.id,
                        step: COMPONENT_FORM_STEPS.PRESENTATION,
                      },
                    )}?parent=${product.parentDigitalProduct}`
                    : formatRoute(
                      ROUTES.PRODUCT.EDIT_FORM,
                      {
                        productId: product.id,
                        step: PRODUCT_FORM_STEPS.PRESENTATION,
                      },
                    )
                  }
              >
                <IconButton
                  outline
                  overrideClassName={canEditOrIsValidatePage ?
                    classNames.updateButton
                    : classNames.disabledButton}
                  overrideClassNames={classNames}
                >
                  <Asset name={ICON_MAP.edit} />
                </IconButton>
              </Link>
            </Tooltip>

            {view !== 'validate' ? (
              <>
                {product.isDraft && canDeleteProduct ?
                  (
                    <Tooltip title={<FormattedMessage {...messages.REMOVE_PRODUCT} />}>
                      <IconButton
                        outline
                        onClick={onOpenDeleteModal}
                        overrideClassName={classNames.deleteButton}
                        overrideClassNames={classNames}
                      >
                        <Asset name={ICON_MAP.trash} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title={<FormattedMessage {...messages.COPY_TO_CLIPBOARD_TOOLTIP} />}>
                      <CopyToClipboard text={reviewReportUrl} onCopy={addSuccessToast}>
                        <IconButton
                          outline
                          overrideClassName={classNames.copyButton}
                          overrideClassNames={classNames}
                        >
                          <Asset name={ICON_MAP.draft} />
                        </IconButton>
                      </CopyToClipboard>
                    </Tooltip>
                  )}
              </>
            ) : <></>}

            {!product.isDraft && (
              <Tooltip title={<FormattedMessage {...messages.SNIPPET_PRODUCT} />}>
                <IconButton
                  outline
                  onClick={onOpenSnippetConfiguration}
                  overrideClassName={classNames.snippetButton}
                  overrideClassNames={classNames}
                  disabled={product.isDraft}
                >
                  <Asset name={ICON_MAP.settings} />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </td>
      </tr>

      <Dialog
        isOpen={isDeleteModalOpen}
        onRequestClose={onCloseDeleteModal}
      >
        <DialogTitle>
          <FormattedMessage {...messages.DELETE_CONFIRM_TITLE} />
        </DialogTitle>

        <DialogContent>
          <FormattedMessage
            {...messages.DELETE_CONFIRM_BODY}
            values={{ productName: product.name }}
          />
        </DialogContent>

        <DialogActions>
          <DialogAction outline onClick={onCloseDeleteModal}>
            <FormattedMessage {...globalMessages.CANCEL} />
          </DialogAction>

          <DialogAction disabled={isLoading} onClick={onRequestDelete}>
            <FormattedMessage {...globalMessages.OK} />
          </DialogAction>
        </DialogActions>
      </Dialog>
    </>
  );
};

ProductRow.displayName = 'ProductRow';

ProductRow.propTypes = {
  product: productShape.isRequired,
  author: userShape,
  isDeleteModalOpen: PropTypes.bool,
  onOpenDeleteModal: PropTypes.func,
  onCloseDeleteModal: PropTypes.func,
  onRequestDelete: PropTypes.func,
  addSuccessToast: PropTypes.func,
  onOpenSnippetConfiguration: PropTypes.func,
  reviewReportUrl: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  view: PropTypes.string,
};

ProductRow.defaultProps = {
  author: {},
  isDeleteModalOpen: false,
  isLoading: false,
  onOpenDeleteModal: noop,
  onCloseDeleteModal: noop,
  onRequestDelete: noop,
  addSuccessToast: noop,
  onOpenSnippetConfiguration: noop,
  view: '',
};

export default enhancer(ProductRow);
