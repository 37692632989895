import React, { memo } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'antd';

import { userShape } from 'shapes/user';

import withCurrentUser from 'react/business/user/withCurrentUser/withCurrentUser.connect';
import withMediumBreakpoint from 'react/hoc/withMediumBreakpoint';

import Avatar from 'react/generic/avatar/Avatar';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import messages from './myspace-header.messages';
import classNames from './myspace-header.module.scss';
import { ID_ALL_LINK } from './myspace-header.constants';

const enhancer = compose(
  withCurrentUser,
  withMediumBreakpoint,
  memo,
);

const MySpaceHeader = ({ user, isBelowMediumBreakpoint, customTitleHeader }) => (
  <div className={classNames.header}>
    <div className={classNames.title}>
      {
        (customTitleHeader)
        || (
          isBelowMediumBreakpoint ? (
            <span>{user.firstName}</span>
          ) : (
            <FormattedMessage {...messages.WELCOME} values={{ name: user.firstName }} />
          )
        )
      }
    </div>
    <div className={classNames.user}>
      <div className={classNames.linkContainer}>
        <Avatar
          src={user?.avatar}
          className={classNames.avatar}
          alt="Avatar"
        />
        <Tooltip
          placement="bottom"
          title={(
            <FormattedMessage
              {...messages.TOOLTIP}
              values={{
                link: (
                  <a href={ID_ALL_LINK} target="_blank" rel="noopener noreferrer" key="id_all">
                    <FormattedMessage {...messages.HERE} />
                  </a>
                ),
              }}
            />
          )}
        >
          <Asset className={classNames.tooltip} name={ICON_MAP.info} />
        </Tooltip>
      </div>
    </div>
  </div>
);

MySpaceHeader.displayName = 'MySpaceHeader';
MySpaceHeader.propTypes = {
  user: userShape.isRequired,
  isBelowMediumBreakpoint: bool,
  customTitleHeader: node,
};

MySpaceHeader.defaultProps = {
  isBelowMediumBreakpoint: false,
  customTitleHeader: null,
};

export default enhancer(MySpaceHeader);
