import { createSelector } from 'reselect';
import moment from 'moment';

export const selectHistoriesMap = state => state.productsHistory.map;

export const selectHistoriesList = createSelector(
  selectHistoriesMap,
  historiesMap => Object.values(historiesMap),
);

/**
 * Return the list of product ids, sorted by date desc.
 */
export const selectHistoryIds = createSelector(
  selectHistoriesList,
  history => [...history]
    .sort((a, b) => (moment(a.date).isBefore(b.date) ? 1 : -1))
    .map(h => h.productId),
);
