import { defineMessages } from 'react-intl';

export default defineMessages({
  UPDATE_PRODUCT: { id: 'preferences.created.table.update', defaultMessage: 'Update' },
  REMOVE_PRODUCT: { id: 'preferences.created.table.remove', defaultMessage: 'Remove draft' },
  SNIPPET_PRODUCT: { id: 'preferences.created.table.snippet', defaultMessage: 'Configure feedback Tangram snippet' },
  COPY_TO_CLIPBOARD_TOOLTIP: { id: 'preferences.created.copyClipboard.tooltip', defaultMessage: 'Copy url ranking for my product to my clipboard.' },
  COPY_TO_CLIPBOARD_SUCCESS: { id: 'preferences.created.copyClipboard.success', defaultMessage: 'The feedback report url of the product {productName} is copied to your clipboard.' },
  DELETE_CONFIRM_TITLE: { id: 'preferences.created.table.dialog.remove.title', defaultMessage: 'Remove a draft' },
  DELETE_CONFIRM_BODY: { id: 'preferences.created.table.dialog.remove.body', defaultMessage: 'Are you sure that you want to delete your draft for the product {productName}?' },
  EDIT_NOT_ALLOWED: { id: 'preferences.created.table.notAllowed', defaultMessage: 'You are not allowed to edit this product' },
});
