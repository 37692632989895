import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { FormattedMessage } from 'react-intl';
import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import { validationStatuses } from 'redux/products/constants';
import messages from './product-status.messages';
import classNames from './product-status.module.scss';
import { userShape } from '../../../../../../shapes/user';

const validationStatusMessages = {
  [validationStatuses.PDL]: 'PDL',
  [validationStatuses.PCA]: 'PCA',
  [validationStatuses.RBDL]: 'RBDL',
  [validationStatuses.RBCA]: 'RBCA',
  [validationStatuses.RBUA]: 'RBUA',
  [validationStatuses.PBUA]: 'PBUA',
  [validationStatuses.RBBUA]: 'RBBUA',
  [validationStatuses.RDLV]: 'RDLV',
  [validationStatuses.RCA]: 'RCA',
  [validationStatuses.PP]: 'PP',
};

const getTextColour = status => (
  status === validationStatuses.PDL
  || status === validationStatuses.PCA
  || status === validationStatuses.PP ?
    classNames.validationPendingText : classNames.validationRejectedText
);

const getValidatorFullName = (status, validators) => {
  if ((status === validationStatuses.PDL
    || status === validationStatuses.RDLV
    || status === validationStatuses.RBDL) && validators.domainLeader) {
    const { firstName, lastName } = validators.domainLeader;
    return `(${firstName} ${lastName})`;
  }

  if ((status === validationStatuses.PCA || status === validationStatuses.RBCA)
    && validators.communityArchitect) {
    const { firstName, lastName } = validators.communityArchitect;
    return `(${firstName} ${lastName})`;
  }

  if (
    (status === validationStatuses.PBUA || status === validationStatuses.RBBUA)
    && validators.businessUnitArchitect) {
    const { firstName, lastName } = validators.businessUnitArchitect;
    return `(${firstName} ${lastName})`;
  }

  return '';
};

const ProductStatus = ({ isDraft, validationStatus, validators }) => (
  <>
    <div className={cn(classNames.container, isDraft && classNames.draft)}>
      <Asset
        name={isDraft ? ICON_MAP.draft : ICON_MAP.check_circle}
        className={classNames.icon}
      />

      <FormattedMessage {...(isDraft ? messages.DRAFT : messages.PUBLISHED)} />
    </div>

    {validationStatus
      && validationStatus !== validationStatuses.APPROVED
      && validationStatusMessages[validationStatus]
      && (
      <div className={cn(classNames.validationStatusText, getTextColour(validationStatus))}>
        <FormattedMessage {...messages[validationStatusMessages[validationStatus]]} />
        <p>{getValidatorFullName(validationStatus, validators)}</p>
      </div>
      )}
  </>
);

ProductStatus.displayName = 'ProductStatus';

ProductStatus.propTypes = {
  isDraft: PropTypes.bool,
  validationStatus: PropTypes.string,
  validators: PropTypes.objectOf(
    PropTypes.shape({
      domainLeader: userShape,
      communityArchitect: userShape,
      businessUnitArchitect: userShape,
    }),
  ),
};

ProductStatus.defaultProps = {
  isDraft: false,
  validators: {},
  validationStatus: '',
};

export default ProductStatus;
