import { defineMessages } from 'react-intl';

export default defineMessages({
  DRAFT: { id: 'preferences.created.table.status.draft', defaultMessage: 'Draft' },
  PUBLISHED: { id: 'preferences.created.table.status.published', defaultMessage: 'Published' },
  PDL: { id: 'preferences.created.table.validation.status.PDL', defaultMessage: 'Pending Domain Leader Validation' },
  RBDL: { id: 'preferences.created.table.validation.status.RBDL', defaultMessage: 'Rejected By Domain Leader' },
  RBCA: { id: 'preferences.created.table.validation.status.RBCA', defaultMessage: 'Rejected By Platform Architect' },
  RBBUA: { id: 'preferences.created.table.validation.status.RBBUA', defaultMessage: 'Rejected By Business Unit Architect' },
  RCA: { id: 'preferences.created.table.validation.status.RCA', defaultMessage: 'Require Platform Architect Validation' },
  PCA: { id: 'preferences.created.table.validation.status.PCA', defaultMessage: 'Pending Platform Architect Validation' },
  PBUA: { id: 'preferences.created.table.validation.status.PBUA', defaultMessage: 'Pending Business Unit Architect Validation' },
  RDLV: { id: 'preferences.created.table.validation.status.RDLV', defaultMessage: 'Require Domain Leader Validation' },
  RBUA: { id: 'preferences.created.table.validation.status.RBUA', defaultMessage: 'Require Business Unit Architect Validation' },
  PP: { id: 'preferences.created.table.validation.status.PP', defaultMessage: 'Pending Publishing' },
});
