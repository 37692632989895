import { compose, withHandlers } from 'recompose';

import connect from 'react/hoc/connectProxy';

import {
  setNotificationsAsRead,
  removeNotifications,
  getNotifications,
  getUnreadNotificationsCount,
} from 'redux/notifications/actions';

import { getNotificationIds, getUnreadNotifications } from '../utils';

export default compose(
  connect(
    null,
    {
      onSetNotificationsAsRead: setNotificationsAsRead,
      onSetRemoveNotifications: removeNotifications,
      onGetNotifications: getNotifications,
      onGetUnreadNotificationsCount: getUnreadNotificationsCount,
    },
  ),

  withHandlers({
    onClickSetAllRead: ({
      notifications,
      onSetNotificationsAsRead,
      onGetUnreadNotificationsCount,
    }) => async () => {
      const notificationsNotRead = getUnreadNotifications(notifications);

      if (notificationsNotRead.length) {
        const notificationIds = getNotificationIds(notificationsNotRead);
        await onSetNotificationsAsRead(notificationIds);
        await onGetUnreadNotificationsCount();
      }
    },
    onClickRemoveAll: ({
      notifications,
      onSetRemoveNotifications,
      onGetNotifications,
      filter,
    }) => async () => {
      if (notifications.length) {
        const notificationIds = getNotificationIds(notifications);
        await onSetRemoveNotifications(notificationIds);
        onGetNotifications({ types: filter, limit: 10 });
      }
    },
  }),
);
