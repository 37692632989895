import { compose } from 'recompose';

import { defaultGetNotificationIdFromProps } from './defaultGetNotificationIdFromProps';
import withRemoveNotificationFromPropsConnect from './withRemoveNotificationFromProps.connect';
import withSetNotificationReadFromPropsConnect from './withSetNotificationReadFromProps.connect';

/**
 * Like withNotificationsManagement hoc, but reads the notificationId from the component props.
 *
 * @param {Function} getNotificationIdFromProps - Get notification id from props. (props) => string.
 * Default is props.notification.id.
 * @returns {Function} HOC.
 */
export default (getNotificationIdFromProps = defaultGetNotificationIdFromProps) => compose(
  withRemoveNotificationFromPropsConnect(getNotificationIdFromProps),
  withSetNotificationReadFromPropsConnect(getNotificationIdFromProps),
);
