import connect from 'react/hoc/connectProxy';

import { getNotifications } from 'redux/notifications/actions';

export default connect(
  null,
  {
    onGetNotifications: getNotifications,
  },
);
