import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from './kind.module.scss';
import messages from './kind.messages';

const Kind = ({ isComponent }) => (
  <>
    <div className={classNames.kindContainer}>
      <FormattedMessage {...(isComponent ? messages.COMPONENT : messages.PRODUCT)} />
    </div>
  </>
);

Kind.displayName = 'Kind';

Kind.propTypes = {
  isComponent: PropTypes.bool.isRequired,
};

export default Kind;
