import connect from 'react/hoc/connectProxy';
import { selectIsSetNotificationReadLoading } from 'redux/notifications/selectors';

import { defaultGetNotificationIdFromProps } from './defaultGetNotificationIdFromProps';

/**
 * Expose the loading status of the setNotificationRead action for a notification.
 *
 * @param {Function} getNotificationIdFromProps - Get notification id from props. (props) => string.
 * Default is props.notification.id.
 * @returns {Function} HOC.
 */
export default (getNotificationIdFromProps = defaultGetNotificationIdFromProps) => connect(
  (state, props) => ({
    isSetNotificationReadLoading: selectIsSetNotificationReadLoading(
      state,
      getNotificationIdFromProps(props),
    ),
  }),
);
