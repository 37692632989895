import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  compose,
  withProps,
  withHandlers,
} from 'recompose';
import { formatRoute } from 'react-router-named-routes';
import { FormattedMessage } from 'react-intl';
import { Switch } from 'antd';

import { PRODUCT_VIEW } from 'config/constants';
import ROUTES from 'react/routes';

import { productShape } from 'shapes/product';
import { preferencesShape } from 'shapes/preferences';

import FollowedProductButton from 'react/business/products/followButton/FollowProductButton';

import { selectIsProductFollowed } from 'redux/users/selectors';
import withUserFollowedProducts from 'react/business/preferences/connect/withUserFollowedProducts.connect';
import withUserPreferences from 'react/business/preferences/connect/withUserPreferences.connect';
import withSetUserPreferences from 'react/business/preferences/connect/withSetUserPreferences.connect';

import Link from 'react/generic/link/Link';

import Kind from 'react/generic/kind/Kind';
import messages from './preferences.followed.messages';
import classNames from './preferences.followed.module.scss';

const enhancer = compose(
  withUserFollowedProducts,
  withUserPreferences,
  withSetUserPreferences,

  withHandlers({
    onSwitchReviewChange: ({ preferences, setPreferences }) => async (productId) => {
      const oldSetting = preferences.productNotifications[productId];
      const newValue = !oldSetting.followReview;

      const newPreferences = {
        ...preferences,
        productNotifications: {
          ...preferences.productNotifications,
          [productId]: { ...oldSetting, followReview: newValue },
        },
      };
      await setPreferences(newPreferences);
    },
    onSwitchUpdateChange: ({ preferences, setPreferences }) => async (productId) => {
      const oldSetting = preferences.productNotifications[productId];
      const newValue = !oldSetting.followUpdate;

      const newPreferences = {
        ...preferences,
        productNotifications: {
          ...preferences.productNotifications,
          [productId]: { ...oldSetting, followUpdate: newValue },
        },
      };
      await setPreferences(newPreferences);
    },
    onSwitchSuggestionChange: ({ preferences, setPreferences }) => async (productId) => {
      const oldSetting = preferences.productNotifications[productId];
      const newValue = !oldSetting.followSuggestion;

      const newPreferences = {
        ...preferences,
        productNotifications: {
          ...preferences.productNotifications,
          [productId]: { ...oldSetting, followSuggestion: newValue },
        },
      };
      await setPreferences(newPreferences);
    },
  }),

  memo,
);

const EnhancedFollowButton = compose(
  withProps({ withToggle: true, selectIsProductFollowed }),
)(FollowedProductButton);

const PreferencesFollowedPage = ({
  myProducts,
  preferences,
  onSwitchReviewChange,
  onSwitchUpdateChange,
  onSwitchSuggestionChange,
}) => (
  <div className={classNames.container}>
    {myProducts.length > 0 && (
      <div className={classNames.title}>
        <FormattedMessage {...messages.FOLLOWED_PRODUCT_TITLE} />
      </div>
    )}

    {myProducts.length > 0 ? (
      <div className={classNames.table}>
        <table>
          <thead>
            <tr>
              <th />
              <th><FormattedMessage {...messages.TABLE_KIND} /></th>
              <th><FormattedMessage {...messages.TABLE_REVIEW} /></th>
              <th><FormattedMessage {...messages.TABLE_SUGGESTIONS} /></th>
              <th><FormattedMessage {...messages.TABLE_UPDATE} /></th>
              <th><FormattedMessage {...messages.TABLE_STATUS} /></th>
            </tr>
          </thead>
          <tbody>
            {myProducts.map((p) => {
              if (!preferences.productNotifications || !preferences.productNotifications[p.id]) {
                return null;
              }

              return (
                <tr key={p.id}>
                  <td className={classNames.cellName}>
                    <Link
                      to={p.parentDigitalProduct ? formatRoute(ROUTES.COMPONENT.PRESENTATION, {
                        componentId: p.id,
                        view: PRODUCT_VIEW.OVERVIEW,
                      }) : formatRoute(ROUTES.PRODUCT.PRESENTATION, {
                        productId: p.id,
                        view: PRODUCT_VIEW.OVERVIEW,
                      })}
                    >
                      {p.name}
                    </Link>
                  </td>
                  <td className={classNames.cellKind}>
                    <Kind isComponent={!!p?.parentDigitalProduct} />
                  </td>
                  <td className={classNames.cellSwitch}>
                    <Switch
                      checked={preferences.productNotifications[p.id].followReview}
                      onChange={() => onSwitchReviewChange(p.id)}
                    />
                  </td>
                  <td className={classNames.cellSwitch}>
                    <Switch
                      checked={preferences.productNotifications[p.id].followSuggestion}
                      onChange={() => onSwitchSuggestionChange(p.id)}
                    />
                  </td>
                  <td className={classNames.cellSwitch}>
                    <Switch
                      checked={preferences.productNotifications[p.id].followUpdate}
                      onChange={() => onSwitchUpdateChange(p.id)}
                    />
                  </td>
                  <td className={classNames.cellStatus}>
                    <div className={classNames.center}>
                      <EnhancedFollowButton productId={p.id} productName={p.name} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    ) : (
      <div className={classNames.empty}>
        <FormattedMessage {...messages.NO_DATA} />
      </div>
    )}
  </div>
);

PreferencesFollowedPage.displayName = 'PreferencesFollowedPage';
PreferencesFollowedPage.propTypes = {
  myProducts: PropTypes.arrayOf(productShape),
  preferences: preferencesShape.isRequired,
  onSwitchReviewChange: PropTypes.func.isRequired,
  onSwitchUpdateChange: PropTypes.func.isRequired,
  onSwitchSuggestionChange: PropTypes.func.isRequired,
};
PreferencesFollowedPage.defaultProps = {
  myProducts: [],
};

export default enhancer(PreferencesFollowedPage);
