import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { compose, lifecycle } from 'recompose';
import connect from 'react/hoc/connectProxy';
import { FormattedMessage } from 'react-intl';

import { PRODUCT_HISTORY_SIZE } from 'config/constants';
import { getProductsOrComponents } from 'redux/products/actions';
import { selectProductsByIds } from 'redux/products/selectors';
import { ACTIONS as HISTORY_ACTIONS } from 'redux/histories/constants';
import { getProductsHistory } from 'redux/histories/actions';
import { selectHistoryIds } from 'redux/histories/selectors';
import { productShape } from 'shapes/product';

import ProductGrid from 'react/business/products/grid/ProductGrid';

import messages from './preferences.history.messages';
import classNames from './preferences.history.module.scss';

const enhancer = compose(
  connect(
    state => ({
      products: selectProductsByIds(state, selectHistoryIds(state)),
    }),
    {
      getProductsHistory,
      getProductsOrComponents,
    },
  ),

  lifecycle({
    /** Get the 10 last product history for the current user. */
    async componentDidMount() {
      const action = await this.props.getProductsHistory({ limit: PRODUCT_HISTORY_SIZE });

      if (action.type === HISTORY_ACTIONS.GET_CURRENT_USER_PRODUCTS_HISTORY_SUCCESS) {
        this.props.getProductsOrComponents(action.history.map(h => h.productId));
      }
    },
  }),

  memo,
);

const HistoryPage = ({ products }) => (
  <div className={classNames.container}>
    { !isEmpty(products) ? (
      <ProductGrid
        className={classNames.content}
        products={products}
      />
    ) : (
      <div className={classNames.empty}>
        <FormattedMessage {...messages.NO_DATA} />
      </div>
    ) }
  </div>
);

HistoryPage.displayName = 'PreferencesHistoryPage';

HistoryPage.propTypes = {
  products: PropTypes.arrayOf(productShape),
};

HistoryPage.defaultProps = {
  products: [],
};

export default enhancer(HistoryPage);
