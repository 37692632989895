import { compose, lifecycle } from 'recompose';
import connect from 'react/hoc/connectProxy';

import { selectCurrentUserFollowedProductList } from 'redux/users/selectors';
import { getMyFollowedProducts } from 'redux/users/actions';

export default compose(
  connect(state => ({
    myProducts: selectCurrentUserFollowedProductList(state),
  }), {
    getMyFollowedProducts,
  }),

  lifecycle({
    /** Load my followed products on mount. */
    componentDidMount() {
      this.props.getMyFollowedProducts({ populate: true });
    },
  }),
);
